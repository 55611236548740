/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { Button, Card, Icon, Link, Stack } from "@mui/material";
// @mui material components
import Grid from "@mui/material/Grid";
import { httpReauest } from "api";
import colors from "assets/theme/base/colors";

// Vision UI Dashboard React base styles
import linearGradient from "assets/theme/functions/linearGradient";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiProgress from "components/VuiProgress";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard React contexts
import { setDirection, useVisionUIController } from "context";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import BarChart from "examples/Charts/BarCharts/BarChart";

// Data
import LineChart from "examples/Charts/LineCharts/LineChart";
import Footer from "examples/Footer";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import OrderOverview from "layouts/rtl/components/OrderOverview";
import Projects from "layouts/rtl/components/Projects";
import ReferralTracking from "layouts/rtl/components/ReferralTracking";
import SatisfactionRate from "layouts/rtl/components/SatisfactionRate";

// Dashboard layout components
import WelcomeMark from "layouts/rtl/components/WelcomeMark";
import { barChartDataDashboard } from "layouts/rtl/data/barChartData";
import { barChartOptionsDashboard } from "layouts/rtl/data/barChartOptions";
import { lineChartDataDashboard } from "layouts/rtl/data/lineChartData";
import { lineChartOptionsDashboard } from "layouts/rtl/data/lineChartOptions";
import { useEffect, useState } from "react";
import { FaShoppingCart } from "react-icons/fa";

// React icons
import { IoIosRocket } from "react-icons/io";
import { IoBuild, IoDocumentText, IoGlobe, IoPerson, IoWallet } from "react-icons/io5";

function RTL() {
  const { gradients } = colors;
  const { cardContent } = gradients;

  const [data, setData] = useState([]);
  const [loading, setloading] = useState(true);
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const user = JSON.parse(localStorage.getItem("user"));
    const newdata = await httpReauest("GET", "/admin/data", {}, { "x-access-token": user.token });
    setData(newdata?.data?.data);
    setloading(false);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} width={"100%"} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "تعداد کاربران", fontWeight: "regular" }}
                count={data?.userCount}
                icon={{ color: "info", component: <IoPerson size="22px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} width={"100%"} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "درآمد شما" }}
                count={data?.income?.toLocaleString() + " تومان "}
                icon={{ color: "info", component: <IoWallet size="22px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} width={"100%"} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "محصولات در سایت" }}
                count={data?.producCount}
                icon={{ color: "info", component: <IoDocumentText size="22px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} width={"100%"} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "سفارشات" }}
                count={data?.orderCount}
                icon={{ color: "info", component: <FaShoppingCart size="20px" color="white" /> }}
              />
            </Grid>
          </Grid>
        </VuiBox>
        <VuiBox mb={3}>
          <Grid container spacing="18px">
            <Grid item xs={12} width={"100%"} lg={6} xl={5}>
              <VuiTypography color="white" variant="button" fontWeight="regular" mb="12px">
                امروز میخوای چیکار کنی؟
              </VuiTypography>
              <VuiBox display="flex" flexWrap="wrap" spacing="18px" gap="18px" my={3}>
                <Grid item xs={12} width={"100%"}>
                  <Card>
                    <Link href="/" style={{ width: "100%" }}>
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        style={{ color: "#fff" }}
                      >
                        ایجاد رویداد
                        <Icon sx={{ fontWeight: "bold", ml: "5px" }}>arrow_backward</Icon>
                      </Button>
                    </Link>
                  </Card>
                </Grid>
                <Grid item xs={12} width={"100%"}>
                  <Card>
                    <Link href="/" style={{ width: "100%" }}>
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        style={{ color: "#fff" }}
                      >
                        ایجاد محصول
                        <Icon sx={{ fontWeight: "bold", ml: "5px" }}>arrow_backward</Icon>
                      </Button>
                    </Link>
                  </Card>
                </Grid>
              </VuiBox>
              {/* <VuiBox display="flex" gap="18px">
                <Grid item xs={12} width={"100%"} lg={6} xl={6}>
                  <Card>
                    <Link href="/" style={{ width: "100%" }}>
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        style={{ color: "#fff" }}
                      >
                        ایجاد محصول
                        <Icon sx={{ fontWeight: "bold", ml: "5px" }}>arrow_backward</Icon>
                      </Button>
                    </Link>
                  </Card>
                </Grid>
                <Grid item xs={12} width={"100%"} lg={6} xl={6}>
                  <Card>
                    <Link href="/" style={{ width: "100%" }}>
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        style={{ color: "#fff" }}
                      >
                        مشاهده همه سفارشات
                        <Icon sx={{ fontWeight: "bold", ml: "5px" }}>arrow_backward</Icon>
                      </Button>
                    </Link>
                  </Card>
                </Grid>
              </VuiBox> */}
            </Grid>
            <Grid item xs={12} width={"100%"} lg={6} xl={7}>
              <SatisfactionRate data={data?.newOrders} />
            </Grid>
            <Grid item xs={12} width={"100%"} lg={6} xl={7}>
              <ReferralTracking data={data?.newProduct} />
            </Grid>
            <Grid item xs={12} width={"100%"} lg={6} xl={5}>
              <WelcomeMark data={data?.newUsers} />
            </Grid>
          </Grid>
        </VuiBox>
        {/* <VuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} width={"100%"} lg={7}>
              <Card>
                <VuiBox sx={{ height: "100%" }}>
                  <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                    نظرة عامة على المبيعات
                  </VuiTypography>
                  <VuiBox display="flex" alignItems="center" mb="40px">
                    <VuiTypography variant="button" color="success" fontWeight="bold">
                      + 5٪ أكثر
                      <VuiTypography variant="button" color="text" fontWeight="regular">
                        في عام 2021
                      </VuiTypography>
                    </VuiTypography>
                  </VuiBox>
                  <VuiBox sx={{ height: "310px" }}>
                    <LineChart
                      lineChartData={lineChartDataDashboard}
                      lineChartOptions={lineChartOptionsDashboard}
                    />
                  </VuiBox>
                </VuiBox>
              </Card>
            </Grid>
            <Grid item xs={12} width={"100%"} lg={5}>
              <Card>
                <VuiBox>
                  <VuiBox
                    mb="24px"
                    height="220px"
                    sx={{
                      background: linearGradient(
                        cardContent.main,
                        cardContent.state,
                        cardContent.deg
                      ),
                      borderRadius: "20px",
                    }}
                  >
                    <BarChart
                      barChartData={barChartDataDashboard}
                      barChartOptions={barChartOptionsDashboard}
                    />
                  </VuiBox>
                  <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                    المستخدمين النشطين
                  </VuiTypography>
                  <VuiBox display="flex" alignItems="center" mb="40px">
                    <VuiTypography variant="button" color="success" fontWeight="bold">
                      (+23)
                      <VuiTypography variant="button" color="text" fontWeight="regular">
                        من الأسبوع الماضي
                      </VuiTypography>
                    </VuiTypography>
                  </VuiBox>
                  <Grid container spacing="50px">
                    <Grid item xs={6} md={3} lg={3}>
                      <Stack
                        direction="row"
                        spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
                        mb="6px"
                      >
                        <VuiBox
                          bgColor="info"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
                        >
                          <IoWallet color="#fff" size="12px" />
                        </VuiBox>
                        <VuiTypography color="text" variant="button" fontWeight="medium">
                          المستخدمون
                        </VuiTypography>
                      </Stack>
                      <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
                        32,984
                      </VuiTypography>
                      <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <Stack
                        direction="row"
                        spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
                        mb="6px"
                      >
                        <VuiBox
                          bgColor="info"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
                        >
                          <IoIosRocket color="#fff" size="12px" />
                        </VuiBox>
                        <VuiTypography color="text" variant="button" fontWeight="medium">
                          نقرات
                        </VuiTypography>
                      </Stack>
                      <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
                        2,42M
                      </VuiTypography>
                      <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <Stack
                        direction="row"
                        spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
                        mb="6px"
                      >
                        <VuiBox
                          bgColor="info"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
                        >
                          <FaShoppingCart color="#fff" size="12px" />
                        </VuiBox>
                        <VuiTypography color="text" variant="button" fontWeight="medium">
                          مبيعات
                        </VuiTypography>
                      </Stack>
                      <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
                        2,400$
                      </VuiTypography>
                      <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <Stack
                        direction="row"
                        spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
                        mb="6px"
                      >
                        <VuiBox
                          bgColor="info"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
                        >
                          <IoBuild color="#fff" size="12px" />
                        </VuiBox>
                        <VuiTypography color="text" variant="button" fontWeight="medium">
                          العناصر
                        </VuiTypography>
                      </Stack>
                      <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
                        320
                      </VuiTypography>
                      <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
                    </Grid>
                  </Grid>
                </VuiBox>
              </Card>
            </Grid>
          </Grid>
        </VuiBox> */}
        {/* <Grid container spacing={3}>
          <Grid item xs={12} width={"100%"} md={6} lg={8}>
            <Projects />
          </Grid>
          <Grid item xs={12} width={"100%"} md={6} lg={4}>
            <OrderOverview />
          </Grid>
        </Grid> */}
      </VuiBox>
    </DashboardLayout>
  );
}

export default RTL;
