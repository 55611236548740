import { Button, Card, Grid, Input, Typography } from "@mui/material";
import { httpReauest } from "api";
import VuiBox from "components/VuiBox";
import { CDN_BASE_URL } from "config";
import React, { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { toast } from "react-toastify";

const OrderCard = ({ order, setloading }) => {
  const { userId, products, price, _id, status, reciveDate } = order;
  const [sended, setSended] = useState();
  const [recive_Date, setReciveDate] = useState();

  async function handleProgress() {
    const user = JSON.parse(localStorage.getItem("user"));
    await httpReauest(
      "POST",
      "/order/progress",
      { orderId: _id, reciveDate: recive_Date },
      { "x-access-token": user.token }
    )
      .then(() => {
        toast.success("وضعیت سفارش با موفقیت تغییر یافت");
        setloading(true);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  }

  async function handleRecive() {
    const user = JSON.parse(localStorage.getItem("user"));
    await httpReauest("POST", "/order/recived", { orderId: _id }, { "x-access-token": user.token })
      .then(() => {
        toast.success("وضعیت سفارش با موفقیت تغییر یافت");
        setloading(true);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  }

  return (
    <Grid item xs={12} width={"100%"} md={6} lg={4}>
      <Card>
        <VuiBox display="flex" py={1} justifyContent="space-between">
          <Typography variant="caption" color={"#fff"}>
            نام کاربر:
          </Typography>
          <Typography variant="caption" color={"#fff"}>
            {userId?.f_name} {userId?.l_name}
          </Typography>
        </VuiBox>
        <VuiBox display="flex" py={1} justifyContent="space-between">
          <Typography variant="caption" color={"#fff"}>
            آدرس:
          </Typography>
          <Typography variant="caption" color={"#fff"}>
            {userId?.address}
          </Typography>
        </VuiBox>
        {userId?.desc && (
          <VuiBox display="flex" py={1} justifyContent="space-between">
            <Typography variant="caption" color={"#fff"}>
              متن توضیحی:
            </Typography>
            <Typography variant="caption" color={"#fff"}>
              {userId?.desc}
            </Typography>
          </VuiBox>
        )}

        <VuiBox display="flex" py={1} justifyContent="space-between">
          <Typography variant="caption" color={"#fff"}>
            کد پستی:
          </Typography>
          <Typography variant="caption" color={"#fff"}>
            {userId?.postalCode}
          </Typography>
        </VuiBox>
        <VuiBox display="flex" py={1} justifyContent="space-between">
          <Typography variant="caption" color={"#fff"}>
            شماره همراه:
          </Typography>
          <Typography variant="caption" color={"#fff"}>
            {userId?.phone}
          </Typography>
        </VuiBox>
        <VuiBox display="flex" py={1} justifyContent="space-between">
          <Typography variant="caption" color={"#fff"}>
            قیمت نهایی:
          </Typography>
          <Typography variant="caption" color={"#fff"}>
            {price?.toLocaleString()}تومان
          </Typography>
        </VuiBox>
        <VuiBox display="flex" py={1} justifyContent="space-between">
          <Typography variant="caption" color={"#fff"}>
            وضعیت سفارش:
          </Typography>
          <Typography variant="caption" color={"#fff"}>
            {status}
          </Typography>
        </VuiBox>
        {reciveDate && (
          <VuiBox display="flex" py={1} justifyContent="space-between">
            <Typography variant="caption" color={"#fff"}>
              تاریخ تحویل:
            </Typography>
            <Typography variant="caption" color={"#fff"}>
              {reciveDate}
            </Typography>
          </VuiBox>
        )}
        <VuiBox display="flex" py={1} justifyContent="space-between" flexDirection="column">
          <Typography variant="caption" color={"#fff"}>
            محصولات:
          </Typography>
          {products?.map((product) => {
            return (
              <VuiBox display="flex" py={1} mb={1} justifyContent="space-between">
                <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <img style={{ width: "80px" }} src={CDN_BASE_URL + product?.img} />
                  <Typography variant="overline" color={"#fff"}>
                    {product?.name}
                  </Typography>
                </span>
                <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <Typography variant="caption" color={"#fff"}>
                    تعداد: {product?.count}
                  </Typography>
                </span>
              </VuiBox>
            );
          })}
        </VuiBox>
        {status == "دریافت شده" ? (
          <VuiBox display="flex" alignItems="center" justifyContent="center" gap={2}>
            {" "}
            <FaCheckCircle color="green" size={45} />
            <Typography variant="caption" color={"#fff"}>
              تحویل داده شده
            </Typography>
          </VuiBox>
        ) : status == "در حال اماده سازی" ? (
          sended ? (
            <>
              <VuiBox
                display="flex"
                py={1}
                mb={1}
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="caption" color={"#fff"}>
                  تاریخ تحویل :
                </Typography>
                <Grid item xs={6}>
                  <Input
                    id="reciveDate"
                    name="reciveDate"
                    onChange={(e) => setReciveDate(e.target.value)}
                  />
                </Grid>
              </VuiBox>
              <Button onClick={handleProgress} variant="contained">
                تایید
              </Button>
            </>
          ) : (
            <Button onClick={() => setSended(true)} variant="contained">
              تغییر وضعیت سفارش به ارسال شده
            </Button>
          )
        ) : (
          <Button onClick={handleRecive} variant="contained" style={{ background: "green" }}>
            تغییر وضعیت سفارش به دریافت شده
          </Button>
        )}
      </Card>
    </Grid>
  );
};

export default OrderCard;
