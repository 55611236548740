import React from "react";

import { Card, Icon } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import authorsTableData from "layouts/tables/data/authorsTableData";
import Table from "examples/Tables/Table";

const WelcomeMark = ({ data }) => {
  const columns = [
    { name: "نام", align: "center" },
    { name: "نام خانوادگی", align: "center" },
    { name: "شماره همراه", align: "center" },
  ];

  const rows = data?.map((e) => {
    return {
      نام: <p>{e.f_name}</p>,
      "نام خانوادگی": <p>{e?.l_name}</p>,
      "شماره همراه": <p>{e.phone}</p>,
    };
  });

  return (
    <Card sx={{ height: "340px" }}>
      <VuiBox height="100%" display="flex" flexDirection="column" justifyContent="space-between">
        <VuiBox>
          <VuiTypography color="white" variant="button" fontWeight="regular" mb="12px">
            کاربرانی که به تازگی اضافه شدند
          </VuiTypography>
        </VuiBox>
        <Table columns={columns} rows={rows} />

        <VuiTypography
          component="a"
          href="/users"
          variant="button"
          color="white"
          fontWeight="regular"
          sx={{
            mr: "5px",
            display: "inline-flex",
            alignItems: "center",
            cursor: "pointer",

            "& .material-icons-round": {
              fontSize: "1.125rem",
              transform: `translate(2px, -0.5px)`,
              transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
            },

            "&:hover .material-icons-round, &:focus  .material-icons-round": {
              transform: `translate(6px, -0.5px)`,
            },
          }}
        >
          مشاهده همه کاربران
          <Icon sx={{ fontWeight: "bold", ml: "5px" }}>arrow_backward</Icon>
        </VuiTypography>
      </VuiBox>
    </Card>
  );
};

export default WelcomeMark;
