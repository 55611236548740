import { Grid, Input, Select, Typography, MenuItem, Button, TextareaAutosize } from "@mui/material";
import { httpReauest } from "api";
import VuiBox from "components/VuiBox";
import VuiInput from "components/VuiInput";
import { BASE_URL } from "config";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { FaPlus, FaPlusCircle } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import * as Yup from "yup";
import "react-quill/dist/quill.snow.css";

const CreateProduct = () => {
  const [images, setImages] = useState([]);
  const [previews, setPreview] = useState([]);
  const [img, setImg] = useState();
  const [previewImg, setPreviewImg] = useState();
  const [file, setfile] = useState();
  const [category, setcategory] = useState();
  const [inputArr, setInputArr] = useState([{ type: "text", id: 1 }]);
  const ref1 = useRef();

  
  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }
  
  async function setPreviwImages(i) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreview((current) => [...current, reader.result]);
    };
    reader.readAsDataURL(i);
  }
  
  function handleAttribute() {
    formik.values.attributes.push({ key: "", answar: "" });
    setInputArr([...inputArr, { type: "text", id: inputArr.length + 1 }]);
  }
  function handleDeleteAttribute() {
    const newArr = inputArr.slice(0, inputArr.length - 1);
    setInputArr(newArr);
  }
  
  function handleChange(e) {
    const fils = e.target.files;
    if (fils) {
      for (let index = 0; index < fils.length; index++) {
        if (fils[index].size > 2031517) {
          toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
        } else {
          setImages((current) => [...current, fils[index]]);
          setPreviwImages(fils[index]);
        }
      }
    }
  }
  
  useEffect(() => {
    getData();
  }, []);
  
  async function getData(params) {
    const user = JSON.parse(localStorage.getItem("user"));
    const newdata = await httpReauest(
      "GET",
      "/product/category",
      {},
      { "x-access-token": user.token }
    );
    setcategory(newdata?.data?.data);
  }
  
  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      name: Yup.string().required("الزامی است"),
      price: Yup.string().required("الزامی است"),
      balance: Yup.number().required("الزامی است"),
      category: Yup.string().required("الزامی است"),
      desc: Yup.string().required("الزامی است"),
    });
  };
  
  const formik = useFormik({
    initialValues: {
      name: "",
      price: "",
      special_price: "",
      balance: "",
      category: "",
      desc: "",
      attributes: [{ key: "", answar: "" }],
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      const user = JSON.parse(localStorage.getItem("user"));
      const formdata = new FormData();
      formdata.append("name", values.name);
      formdata.append("price", Number(values.price?.replaceAll(",", "")));
      formdata.append("balance", values.balance);
      formdata.append("desc", values.desc);
      formdata.append("category", values.category);
      formdata.append("attributes", JSON.stringify(values.attributes));
      formdata.append("img", img, img?.name);
      if (values.special_price) {
        formdata.append("special_price", Number(values.special_price?.replaceAll(",", "")));
      }
      if (file) {
        formdata.append("voice", file, file?.name);
      }
      for (let i = 0; i < images.length; i++) {
        formdata.append("images", images[i]);
      }
      await fetch(BASE_URL + "/product", {
        method: "POST",
        body: formdata,
        headers: { "x-access-token": user.token },
      })
      .then((dat) => {
        if (dat?.status == 201) {
          toast.success("محصول ساخته شد");
          formik.resetForm();
          setImg(null);
          setfile(null);
          setImages(null);
          setPreviewImg(null);
          setPreview(null);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
    },
  });

  
  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }

  function handleChangePrice(params) {
    const value = params.target.value;
    const formattedValue = (Number(value.replace(/\D/g, "")) || "").toLocaleString();
    formik.setFieldValue("price", formattedValue);
  }

  function handleChangeSpecialPrice(params) {
    const value = params.target.value;
    const formattedValue = (Number(value.replace(/\D/g, "")) || "").toLocaleString();
    formik.setFieldValue("special_price", formattedValue);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} width={"100%"} md={6} lg={3}>
              <Typography variant="subtitle2" mb={1} color={"#fff"}>
                اسم محصول:*
              </Typography>
              <VuiInput
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.name && formik.touched.name && (
                <small style={{ color: "red", fontSize: "14px" }}>{formik.errors.name}</small>
              )}
            </Grid>
            <Grid item xs={12} width={"100%"} md={6} lg={3}>
              <Typography variant="subtitle2" mb={1} color={"#fff"}>
                قیمت:*
              </Typography>
              <VuiInput
                id="price"
                name="price"
                value={formik.values.price.toLocaleString()}
                onChange={handleChangePrice}
                onBlur={formik.handleBlur}
              />
              {formik.errors.price && formik.touched.price && (
                <small style={{ color: "red", fontSize: "14px" }}>{formik.errors.price}</small>
              )}
            </Grid>
            <Grid item xs={12} width={"100%"} md={6} lg={3}>
              <Typography variant="caption" mb={1} color={"#fff"}>
                قیمت با تخفیف(در صورتی که تخفیف ندارد خالی بزارید):
              </Typography>
              <VuiInput
                id="special_price"
                name="special_price"
                value={formik.values.special_price.toLocaleString()}
                onChange={handleChangeSpecialPrice}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12} width={"100%"} md={6} lg={3}>
              <Typography variant="subtitle2" mb={1} color={"#fff"}>
                تعداد:*
              </Typography>
              <VuiInput
                type="number"
                id="balance"
                name="balance"
                value={formik.values.balance}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.balance && formik.touched.balance && (
                <small style={{ color: "red", fontSize: "14px" }}>{formik.errors.balance}</small>
              )}
            </Grid>
            <Grid item xs={12} width={"100%"} md={6} lg={3}>
              <Typography variant="subtitle2" mb={1} color={"#fff"}>
                دسته بندی:*
              </Typography>
              <select
                style={{
                  width: "100%",
                  backgroundColor: "#fff",
                  borderRadius: "7px",
                  height: "40px",
                  padding: "0px 10px",
                  fontFamily: "Bnazanin",
                  fontWeight: "500",
                  fontSize: "16px",
                  background: "#0f1535",
                  border: "none",
                  color: "#fff",
                }}
                id="category"
                name="category"
                value={formik.values.category}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option></option>
                {category?.map((category) => {
                  return <option value={category._id}>{category.name}</option>;
                })}
              </select>
              {formik.errors.category && formik.touched.category && (
                <small style={{ color: "red", fontSize: "14px" }}>{formik.errors.category}</small>
              )}
            </Grid>
            <Grid item xs={12} width={"100%"} md={6} lg={3}>
              <Typography variant="subtitle2" mb={1} color={"#fff"}>
                تصویر اصلی:*
              </Typography>
              <label
                htmlFor="img"
                style={{
                  width: "100%",
                  height: "250px",
                  border: "1px dashed #fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                {previewImg ? (
                  <img
                    src={previewImg}
                    style={{ width: "100%", height: "100%", objectFit: "contain" }}
                    alt=""
                  />
                ) : (
                  <>
                    <FaPlus className="center" size={30} />
                  </>
                )}
              </label>
              <input
                onChange={(e) => {
                  handleImg(e);
                }}
                style={{ display: "none" }}
                type="file"
                className="hidden"
                id="img"
                accept="image/png, image/jpg, image/jpeg"
              />
            </Grid>
            <Grid item xs={12} width={"100%"} md={6} lg={3}>
              <Typography variant="subtitle2" mb={1} color={"#fff"}>
                ویس محصول:
              </Typography>
              <input
                onChange={(e) => {
                  setfile(e.target.files[0]);
                }}
                type="file"
                className="hidden"
                id="voice"
                accept="audio/*"
              />
            </Grid>
            <Grid item xs={12} width={"100%"} md={6} lg={3}>
              <Typography variant="subtitle2" mb={1} color={"#fff"}>
                ویژگی:*
              </Typography>
              <div
                ref={ref1}
                style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}
              >
                {inputArr.map((item, i) => {
                  return (
                    <>
                      <input
                        style={{
                          width: "48%",
                          backgroundColor: "#fff",
                          borderRadius: "7px",
                          height: "40px",
                          padding: "5px 10px",
                          fontFamily: "Bnazanin",
                          fontWeight: "500",
                          fontSize: "16px",
                          marginBottom: "8px",
                          background: "#0f1535",
                          border: "none",
                          color: "#fff",
                        }}
                        id={`attributes[${i}].key`}
                        name={`attributes[${i}].key`}
                        className="form-control"
                        value={formik.values.attributes[i]?.key}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="مثلا رنگ"
                      />
                      <input
                        style={{
                          width: "48%",
                          backgroundColor: "#fff",
                          borderRadius: "7px",
                          height: "40px",
                          padding: "5px 10px",
                          fontFamily: "Bnazanin",
                          fontWeight: "500",
                          fontSize: "16px",
                          marginBottom: "8px",
                          background: "#0f1535",
                          border: "none",
                          color: "#fff",
                        }}
                        value={formik.values.attributes[i]?.answar}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id={`attributes[${i}].answar`}
                        name={`attributes[${i}].answar`}
                        className="form-control"
                        placeholder="آبی"
                      />
                    </>
                  );
                })}
                <div className="w-full md:w-[48%] md:mb-5 flex">
                  <FaPlusCircle
                    color="#fff"
                    onClick={handleAttribute}
                    className="cursor-pointer"
                    size={28}
                  />
                  {inputArr.length > 1 && (
                    <MdDelete
                      color="#fff"
                      onClick={handleDeleteAttribute}
                      className="cursor-pointer"
                      size={28}
                    />
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} width={"100%"} md={6} lg={6}>
              <Typography variant="subtitle2" mb={1} color={"#fff"}>
                توضیح:*
              </Typography>
              <ReactQuill
                value={formik.values.desc}
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false], font: ["Bnazanin"] }, {}],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                    ["link", "image", "code"],
                    ["clean"],
                  ],
                }}
                formats={[
                  "header",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "blockquote",
                  "list",
                  "bullet",
                  "indent",
                  "link",
                  "image",
                ]}
                onChange={(value) => formik.setFieldValue("desc", value)}
                style={{ direction: "ltr" , background:"#eee "}}
              />

              {formik.errors.desc && formik.touched.desc && (
                <small style={{ color: "red", fontSize: "14px" }}>{formik.errors.desc}</small>
              )}
            </Grid>
            <Grid item xs={12} width={"100%"} md={6} lg={6}>
              <Typography variant="subtitle2" mb={1} color={"#fff"}>
                تصاویر:*
              </Typography>
              <label
                htmlFor="addImage"
                style={{
                  width: "100%",
                  height: "350px",
                  border: "1px dashed #fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                <FaPlus size={25} color="#fff" />
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "15px",
                  }}
                >
                  {previews &&
                    previews.map((src) => {
                      return (
                        <img
                          src={src}
                          style={{ width: "150px", height: "150px", objectFit: "contain" }}
                        />
                      );
                    })}
                </div>
              </label>
              <input
                onChange={(e) => {
                  handleChange(e);
                }}
                style={{ display: "none" }}
                id="addImage"
                type={"file"}
                accept="image/png, image/jpg, image/jpeg"
              />
            </Grid>
            <Grid item xs={12} width={"100%"}>
              <Button type="submit" variant="contained">
                ارسال
              </Button>
            </Grid>
          </Grid>
        </form>
      </VuiBox>
    </DashboardLayout>
  );
};

export default CreateProduct;
