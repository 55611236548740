import { Card, Grid, Typography } from "@mui/material";
import { httpReauest } from "api";
import VuiBox from "components/VuiBox";
import { useVisionUIController } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";

const Users = () => {
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(true);
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const user = JSON.parse(localStorage.getItem("user"));
    const newdata = await httpReauest("GET", "/user", {}, { "x-access-token": user.token });
    setData(newdata?.data?.data);
    setloading(false);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <VuiBox py={3}>
        <Grid container spacing={3}>
          {loading ? (
            <h3>Loading....</h3>
          ) : (
            data?.map((us, i) => {
              return (
                <Grid key={i} item xs={12} width={"100%"} sm={6} xl={3}>
                  <Card>
                    <VuiBox display="flex" py={1} justifyContent="space-between">
                      <Typography variant="caption" color={"#fff"}>
                        نام:
                      </Typography>
                      <Typography variant="caption" color={"#fff"}>
                        {us?.f_name}
                      </Typography>
                    </VuiBox>
                    <VuiBox display="flex" py={1} justifyContent="space-between">
                      <Typography variant="caption" color={"#fff"}>
                        نام خانوادگی:
                      </Typography>
                      <Typography variant="caption" color={"#fff"}>
                        {us?.l_name}
                      </Typography>
                    </VuiBox>
                    <VuiBox display="flex" py={1} justifyContent="space-between">
                      <Typography variant="caption" color={"#fff"}>
                        شماره همراه:
                      </Typography>
                      <Typography variant="caption" color={"#fff"}>
                        {us?.phone}
                      </Typography>
                    </VuiBox>
                  </Card>
                </Grid>
              );
            })
          )}
        </Grid>
      </VuiBox>
    </DashboardLayout>
  );
};

export default Users;
