import React from "react";
import { Card, Icon } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import colors from "assets/theme/base/colors";
import { FaEllipsisH } from "react-icons/fa";
import linearGradient from "assets/theme/functions/linearGradient";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "examples/Tables/Table";
import { CDN_BASE_URL } from "config";

function ReferralTracking({ data }) {
  const { info, gradients } = colors;
  const { cardContent } = gradients;

  const columns = [
    { name: "تصویر", align: "left" },
    { name: "اسم", align: "center" },
    { name: "دسته بندی", align: "center" },
    { name: "قیمت", align: "right" },
  ];

  const rows = data?.map((e) => {
    return {
      تصویر: <img style={{ width: "40px" }} src={CDN_BASE_URL + e.img} />,
      اسم: <p>{e.name}</p>,
      "دسته بندی": <p>{e.category.name}</p>,
      قیمت: <p>{e.price?.toLocaleString()}</p>,
    };
  });

  return (
    <Card sx={{ height: "340px" }}>
      <VuiBox height="100%" display="flex" flexDirection="column" >
        <VuiBox>
          <VuiTypography color="white" variant="button" fontWeight="regular" mb="12px">
            آخرین محصولاتی که اضافه کردید
          </VuiTypography>
        </VuiBox>
        <Table columns={columns} rows={rows} />
      </VuiBox>
    </Card>
  );
}

export default ReferralTracking;
