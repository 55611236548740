import { Grid, Input, Select, Typography, MenuItem, Button, TextareaAutosize } from "@mui/material";
import { httpReauest } from "api";
import VuiBox from "components/VuiBox";
import VuiInput from "components/VuiInput";
import { CDN_BASE_URL } from "config";
import { BASE_URL } from "config";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { FaPlus, FaPlusCircle, FaTrash } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import * as Yup from "yup";

const AllProduct = () => {
  const [data, setData] = useState([]);
  
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const res = await httpReauest("GET", "/product", {}, {});
    setData(res.data.data);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Grid container spacing={3}>
          {data?.map((item) => (
            <Grid item xs={12} width={"100%"} md={6} lg={3}>
              <ProductCard item={item} />
            </Grid>
          ))}
        </Grid>
      </VuiBox>
    </DashboardLayout>
  );
};

const ProductCard = ({ item }) => {
  async function handleDelete() {
    const user = JSON.parse(localStorage.getItem("user"));

    await httpReauest("DELETE", "/product/" + item?._id, {}, { "x-access-token": user.token })
      .then((dat) => {
        if (dat?.status == 201) {
          toast.success("محصول حذف شد");
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  }

  return (
    <div className="product-card">
      <div className="product-image">
        <img src={CDN_BASE_URL + item?.img} alt={item.name} />
      </div>
      <div className="product-info">
        <h3 className="product-name">{item.name}</h3>
        <p className="product-price">{item.price?.toLocaleString()} تومان</p>
      </div>
      <FaTrash style={{cursor:"pointer"}} color="red" onClick={handleDelete} />
    </div>
  );
};

export default AllProduct;
