/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgSignIn from "assets/images/signInImage.png";
import { httpReauest } from "api";
import { toast } from "react-toastify";

function SignIn() {
  const [rememberMe, setRememberMe] = useState(true);
  const [email, setemail] = useState(true);
  const [password, setpassword] = useState(true);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  async function handleSubmit() {
    await httpReauest("POST", "/admin/login", { email, password }, {})
      .then(async (res) => {
        if (res.status == 201) {
          toast.success("موفقیت امیز");
          await localStorage.setItem("user", JSON.stringify(res.data.data));
          window.location.href = window.origin + "/dashboard";
        }
      })
      .catch((data) => {
        toast.error(data?.response?.data?.message);
      });
  }

  async function getData() {
    const data = JSON.parse(localStorage.getItem("user"));
    if (data) {
      await httpReauest("POST", "/admin/islogined", {}, { "x-auth-token": data?.token }).then(
        (res) => {
          if (res.status == 201) {
            window.location.href = window.origin + "/dashboard";
          }
        }
      );
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <CoverLayout
      title="خوش آمدید"
      color="white"
      description="رمز عبور و ایمیل رو وارد کنید"
      premotto="INSPIRED BY THE FUTURE"
      motto="THE DASHBOARD"
      image={bgSignIn}
    >
      <VuiBox component="form" role="form">
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              ایمیل
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            padding="1px"
            borderRadius={borders.borderRadius.lg}
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              type="email"
              placeholder="ایمیل"
              fontWeight="500"
              onChange={(e) => setemail(e.target.value)}
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              رمز
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              type="password"
              placeholder="رمز"
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
              onChange={(e) => setpassword(e.target.value)}
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox mt={4} mb={1}>
          <VuiButton color="info" fullWidth onClick={handleSubmit}>
            تایید
          </VuiButton>
        </VuiBox>
      </VuiBox>
    </CoverLayout>
  );
}

export default SignIn;
