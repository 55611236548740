import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import OrderCard from "./components/OrderCard";
import { Grid, Typography } from "@mui/material";
import { httpReauest } from "api";

const Orders = () => {
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [loading]);

  async function getData() {
    const user = JSON.parse(localStorage.getItem("user"));
    const newdata = await httpReauest("GET", "/order", {}, { "x-access-token": user.token });
    setData(newdata?.data?.data);
    setloading(false);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Typography variant="body2" color={"yellow"}>
        متن توضیحی : وضعیت سفارشات رو از اینجا باید تغییر بدید و یک تاریخ تحویل برای هر سفارش ثبت
        کنید
      </Typography>
      <VuiBox py={3}>
        <Grid container spacing={3}>
          {loading ? (
            <h2>Loading...</h2>
          ) : (
            data?.map((order, i) => {
              return <OrderCard key={i} order={order} setloading={setloading} />;
            })
          )}
        </Grid>
      </VuiBox>
    </DashboardLayout>
  );
};

export default Orders;
