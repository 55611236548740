import React from 'react';

import { Card, Icon, Stack } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import CircularProgress from '@mui/material/CircularProgress';
import { IoHappy } from 'react-icons/io5';
import colors from 'assets/theme/base/colors';
import linearGradient from 'assets/theme/functions/linearGradient';
import Table from 'examples/Tables/Table';
import moment from 'moment';

const SatisfactionRate = ({data}) => {
	const { info, gradients } = colors;
	const { cardContent } = gradients;

	const columns = [
		{ name: "نام کابر", align: "left" },
		{ name: "وضعیت", align: "center" },
		{ name: "تاریخ", align: "center" },
	  ];
	
	  const rows = data?.map((e)=>{
		return ({
			"نام کابر" : <p>{e.userId?.f_name + " " + e.userId.l_name}</p>,
			"وضعیت" : <p>{e?.status}</p>,
			"تاریخ" : <p dir='ltr'>{moment(e?.createdAt).format("YYYY/MM/DD HH:mm")}</p>,
		})
	  })
	  return (
		<Card sx={{ height: "340px" }}>
		  <VuiBox height="100%" display="flex" flexDirection="column" justifyContent="space-between">
			<VuiBox>
			  <VuiTypography color="white" variant="button" fontWeight="regular" mb="12px">
				سفارشاتی که جدید دریافت کردید
			  </VuiTypography>
			</VuiBox>
			<Table columns={columns} rows={rows} />
	
			<VuiTypography
			  component="a"
			  href="/orders"
			  variant="button"
			  color="white"
			  fontWeight="regular"
			  sx={{
				mr: "5px",
				display: "inline-flex",
				alignItems: "center",
				cursor: "pointer",
	
				"& .material-icons-round": {
				  fontSize: "1.125rem",
				  transform: `translate(2px, -0.5px)`,
				  transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
				},
	
				"&:hover .material-icons-round, &:focus  .material-icons-round": {
				  transform: `translate(6px, -0.5px)`,
				},
			  }}
			>
			  مشاهده همه سفارشات
			  <Icon sx={{ fontWeight: "bold", ml: "5px" }}>arrow_backward</Icon>
			</VuiTypography>
		  </VuiBox>
		</Card>
	  );
};

export default SatisfactionRate;
